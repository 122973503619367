exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-registrierkasse-onlineshop-tsx": () => import("./../../../src/pages/registrierkasse-onlineshop.tsx" /* webpackChunkName: "component---src-pages-registrierkasse-onlineshop-tsx" */),
  "component---src-pages-science-fictions-[id]-tsx": () => import("./../../../src/pages/science-fictions/[id].tsx" /* webpackChunkName: "component---src-pages-science-fictions-[id]-tsx" */),
  "component---src-pages-science-fictions-tsx": () => import("./../../../src/pages/science-fictions.tsx" /* webpackChunkName: "component---src-pages-science-fictions-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

